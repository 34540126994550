import { EditorTopBar } from '@components/Editor/TopBar/EditorTopBar';
import { CircularProgress, Paper, Stack, Tab, Tabs } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { FormStatus, useProjectFormDefinitionQuery, useProjectFormQuery } from 'gql/index';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { SavingStatusContextProvider } from '../../../../../components/Editor/TopBar/SavingStatusContext';
import { FormActions } from '../../../components/FormActions';
import { FormEditor } from '../../../components/FormEditor/FormEditor';
import { FormEditorPreviewDrawer } from '../../../components/FormEditorPreviewDrawer';
import { PendingRequestTab } from '../../../components/PendingRequest/PendingRequestTab';
import { useGetFilledForms } from '../../../utils/useGetFilledForms';
import { useGetPendingFillFormTasks } from '../../../utils/useGetPendingTasks';
import { FilledFormsTab } from './FilledFormsTab';

export const FormEditorView: React.FC = () => {
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);
  const { isMobile } = useResponsive();

  const { data: projectForm, isFetching: isProjectFormFetching } = useProjectFormQuery({ formId }, { select: d => d.projectForm });

  const { data: formDefinition, isFetching: isDefinitionFetching, refetch: refetchFormDefinition } = useProjectFormDefinitionQuery({ formId }, {
    cacheTime: 0,
    keepPreviousData: false,
    select: d => d.projectForm?.formDefinition
  });

  const { isFetching: isPendingRequestFetching, pendingTasksCount } = useGetPendingFillFormTasks();
  const { isFetching: isFetchingSubmissions, submissionsCount } = useGetFilledForms();


  const isFetching = isProjectFormFetching || isDefinitionFetching || isPendingRequestFetching || isFetchingSubmissions;
  const [selectedTab, _setSelectedTab] = React.useState<'questions' | 'submissions' | 'pendingRequests'>(isMobile ? 'submissions' : 'questions');
  const setSelectedTab: typeof _setSelectedTab = (tab) => {
    if (tab !== 'submissions') {
      refetchFormDefinition();
    }
    _setSelectedTab(tab);
  };

  useEffect(() => {
    if (isMobile) {
      _setSelectedTab('submissions');
    }
  }, [isMobile]);

  const { formatMessage } = useIntl();
  const [previewMode, setPreviewMode] = useState(false);

  if (!formDefinition) {
    return (
      <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  return <>
    <SavingStatusContextProvider isEditorLoading={isFetching}>
      <EditorTopBar isLoading={isFetching} title={formDefinition.name} />

      <Paper elevation={1} sx={{ zIndex: 1100 }}>
        <Stack direction='row' justifyContent={'space-between'} padding={1}>
          <Tabs centered value={selectedTab} onChange={(_e, value) => setSelectedTab(value)}>
            <Tab label={formatMessage({ id: 'Questions' })} value='questions' />
            <Tab label={`${formatMessage({ id: 'Filled forms' })} (${submissionsCount})`} value='submissions' />
            {projectForm?.status == FormStatus.Sent &&
              <Tab label={`${formatMessage({ id: 'Pending requests' })} (${pendingTasksCount})`} value='pendingRequests' />
            }
          </Tabs>
          {selectedTab === 'questions' && projectForm && !isFetching && !isMobile && (
            <FormActions expanded={!isMobile} projectForm={projectForm} onPreviewClick={() => setPreviewMode(true)} />
          )}
        </Stack>
      </Paper>

      {selectedTab === 'questions' && <>
        <FormEditor formContent={formDefinition} />

        <FormEditorPreviewDrawer
          open={previewMode}
          onClose={() => setPreviewMode(false)}
          projectFormId={Number(formId)}
        />
      </>}

      {selectedTab === 'submissions' &&
        <FilledFormsTab />
      }

      {selectedTab == 'pendingRequests' &&
        <PendingRequestTab />
      }
    </SavingStatusContextProvider>
  </>;
};