import { MeetingTypeSelect } from '@modules/meetings/components/MeetingTypeSelect';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { IWorkflowPrincipal } from '@modules/workflow/types';
import { TextField, TextFieldProps } from '@mui/material';
import { WorkflowFieldType, WorkflowVariableType, WorkflowVariableValueTypes } from 'gql/index';
import React, { useCallback, useContext, useMemo } from 'react';
import typia from 'typia';
import { WorkflowVariableFormValue } from '../types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorTemplateInput } from './WorkflowEditorTemplateInput';

export type WorkflowVariableInput = {
  type: WorkflowVariableType,
  variableId?: number,
  value?: unknown;
};

export type VariableValueInputComponentProps = {
  variableType: WorkflowVariableValueTypes;
  required: boolean;
  onChange: (value: WorkflowVariableFormValue) => void;
  value: WorkflowVariableFormValue | undefined;
  label: string;
  error?: string;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
};

type ComponentProps = VariableValueInputComponentProps & {
  onChange: (newValue: unknown) => void;
}

export const VariableValueInputComponent: React.FC<VariableValueInputComponentProps> = (props) => {
  const onExplicitValueChanged = useCallback((newValue: unknown) => props.onChange({
    id: '',
    name: props.value?.name ?? '',
    variableValueType: props.variableType,
    variableType: WorkflowVariableType.LocalVariable,
    value: newValue
  }), [props]);



  const variableComponents = useMemo<Record<WorkflowVariableValueTypes, React.FC<ComponentProps>>>(() => ({
    DateAndTime: () => { throw new Error('Input not implemented for this variable type'); },
    DateOnly: () => { throw new Error('Input not implemented for this variable type'); },
    Integer: () => { throw new Error('Input not implemented for this variable type'); },
    Decimal: () => { throw new Error('Input not implemented for this variable type'); },
    Document: () => { throw new Error('Input not implemented for this variable type'); },
    FormSubmission: () => { throw new Error('Input not implemented for this variable type'); },
    DocumentSignatureCoordinates: () => { throw new Error('Input not implemented for this variable type'); },
    FormTemplate: ({ value, disabled, required, error, onChange }) => {
      let templateId: number | undefined;

      if (value != null) {
        templateId = Number(value?.value);
      }

      return <WorkflowEditorTemplateInput disabled={disabled} required={required} onChange={onChange} error={error} selectedTemplateId={templateId} />;
    },
    String: ({ label, required, value, disabled, error, textFieldProps, onChange }) => {
      return <TextField
        {...textFieldProps}
        disabled={disabled}
        required={required}
        label={label}
        value={value?.value}
        onChange={e => onChange(e.currentTarget.value)}
        error={!!error}
        helperText={error} />;
    },
    User: ({ label, required, value, disabled, error, onChange }) => {
      const principal = typia.assert<IWorkflowPrincipal | null | undefined>(value?.value);
      return (
        <PrincipalPicker
          label={label}
          disabled={disabled}
          required={required}
          error={error}
          onChange={value => onChange(value.at(0))}
          value={principal ? [principal] : []}
          usersOnly
          includeMe
        />
      );
    },
    SignatureRequest: ({ label, required, value, error, textFieldProps }) => {

      const { actionsFieldArray, actionDefinitionsMap } = useContext(WorkflowEditorContext);
      const actionOutputtingVariable = actionsFieldArray.fields.find(p => p.fieldValues.find(fv => fv.value?.id == value?.id && actionDefinitionsMap[p.workflowActionDefinitionId].fields.find(f => f.id == fv.fieldId)?.fieldType == WorkflowFieldType.OutputOnly));

      return <TextField {...textFieldProps} disabled={true} required={required} label={label} value={actionOutputtingVariable?.name} error={!!error} helperText={error} />;
    },
    MeetingType: ({ label, required, value, disabled, error, onChange }) => {
      const numberValue = value?.value != null ? Number(value?.value) : undefined;
      return <MeetingTypeSelect disabled={disabled}
        required={required}
        label={label}
        value={numberValue}
        onMeetingTypeChange={newValue => onChange(newValue)}
        error={error} />;
    }
  }), []);

  const Component = useMemo(() => variableComponents[props.variableType], [props.variableType, variableComponents]);

  return <Component {...props} onChange={onExplicitValueChanged} />;
};
