import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { isNavigationMenuOpenState, isSettingsMenuOpenState } from '@modules/application/atoms';
import { shouldShowJiraWidget } from '@modules/application/utils';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Stack, Toolbar, Typography, styled } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { useTenantThemeQuery } from 'gql/index';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { TopBarAppLogo } from './TopBarAppLogo';
import { TrialInfo } from './TrialInfo';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: theme.palette.divider
}));

export const TopBar: React.FC = () => {
  const { projectId: projectIdString } = useParams();
  const { data: tenantTheme } = useTenantThemeQuery(undefined, { select: d => d.tenantTheme });

  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const { name, projectId } = useCurrentProject({ disabled: !projectIdString });

  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useRecoilState(isNavigationMenuOpenState);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useRecoilState(isSettingsMenuOpenState);
  const toggleNavigationMenu = () => setIsNavigationMenuOpen(!isNavigationMenuOpen);
  const toggleSettingsMenu = () => setIsSettingsMenuOpen(!isSettingsMenuOpen);

  const { isExternalUser, me } = useGetMe();

  const isJiraWidgetShown = useMemo(() => shouldShowJiraWidget({
    isBrandingEnabled: tenantTheme?.isBrandingEnabled ?? true,
    isExternalUser
  }), [isExternalUser, tenantTheme?.isBrandingEnabled]);

  return (
    <StyledAppBar
      color="default"
      position="sticky"
      elevation={1}
    >
      <Toolbar sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <Stack
          flex={1}
          pr={isJiraWidgetShown ? 5.5 : 0}  // keeps space for jira help widget
        >
          <Stack flexGrow={1} direction='row' justifyContent='space-between' alignItems='center' gap={1}>
            <Stack direction='row' alignItems='center' spacing={2} flex={1}>
              {(!isExternalUser) && (
                <IconButton edge="start" color="inherit" onClick={toggleNavigationMenu}>
                  <MenuIcon />
                </IconButton>
              )}

              <Box onClick={() => getTenantIdentifier() && navigate(`/${getTenantIdentifier()}/projects`)} sx={{ minWidth: '120px', cursor: getTenantIdentifier() ? 'pointer' : 'default' }}>
                <TopBarAppLogo />
              </Box>
            </Stack>

            <Stack gap={1} flex={1} >

              {name && !isMobile && (
                <Typography variant='h6' sx={{ cursor: 'pointer' }} onClick={() => navigate(`/${getTenantIdentifier()}/projects/${projectId}`)} flex={1} textAlign={'center'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} mx={4}>
                  {name}
                </Typography>
              )}


              {(!name || isMobile) && <TrialInfo />}


            </Stack>

            <Stack alignItems='center' direction={'row'} justifyContent={'end'} spacing={4} flex={1}>
              <IconButton onClick={toggleSettingsMenu} sx={{ p: '6px' }} size='large'>
                <UserAvatar displayName={me?.fullName ?? ''} sx={{ width: '50px', height: '50px' }} size='large' />
              </IconButton>
            </Stack>
          </Stack>

          {name && !isMobile && <TrialInfo />}
        </Stack>
      </Toolbar>
    </StyledAppBar >
  );
};